<template>
  <form
    role="ignore-empty-submit"
    action="/search/topics"
    accept-charset="UTF-8"
    method="get"
    autocomplete="off"
    @submit.prevent="handleSubmit"
    class="pc-only"
  >
    <div class="search">
      <search-input />
      <button class="search__button button button--primary" type="submit">
        <i class="glyphs glyphs-search" aria-hidden="true"></i>
      </button>
      <div class="search-suggest-wrapper" v-if="search_box_active">
        <search-suggestions v-bind:isSecond="this.isSecond" />
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import utils from '../../utils';

import SearchInput from './SearchInput.vue';
import SearchSuggestions from './SearchSuggestions.vue';

export default {
  components: { SearchInput, SearchSuggestions },
  computed: {
    ...mapGetters(['search_box_active', 'current_keyword'])
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
  },
  methods: {
    ...mapActions(['disableSearchBox', 'resetFocusCandidateIndex']),
    handleClickOutside(e) {
      if (e.target && !this.$el.contains(e.target)) {
        this.resetFocusCandidateIndex();
        this.disableSearchBox();
      }
    },
    handleSubmit() {
      utils.goToSearchURL('/search/topics', {
        q: utils.replaceSlash(this.current_keyword.trim())
      });
    }
  },
  props: {
    isSecond: {
      type: Boolean,
      default: false
    }
  }
};
</script>
