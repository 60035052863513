export function showToast(message) {
  const toastEl = document.createElement('div');
  toastEl.className = 'popover popover-bottom--show popover-bottom';
  toastEl.textContent = message;
  document.body.appendChild(toastEl);
}

// FIXME: we should implement this as vue component
export function showFadeDialog(message) {
  const p = document.createElement('p');
  p.textContent = message;

  const fadeDialogInr = document.createElement('div');
  fadeDialogInr.className = 'fade-dialog-inr';
  fadeDialogInr.appendChild(p);

  const fadeDialogArea = document.createElement('div');
  fadeDialogArea.className = 'fade-dialog-area';
  fadeDialogArea.appendChild(fadeDialogInr);

  const fadeDialogEl = document.createElement('div');
  fadeDialogEl.className = 'fade-dialog fade-in-bottom';
  fadeDialogEl.appendChild(fadeDialogArea);

  document.body.appendChild(fadeDialogEl);
}
