<template>
  <transition name="modal">
    <div
      class="modal-mask modal-mask-scroll-sp modal-center"
      v-if="cannotReceiveCurrentNotification"
    >
      <div class="modal-wrapper">
        <div class="saved-dialog">
          <div>
            <div class="text-center saved-dialog-title">
              <h2 class="heading2">メールアドレスが未登録です</h2>
            </div>
            <div class="saved-dialog-body">
              <p>新着メールを受け取るため、下記より登録してください。</p>
              <button
                type="button"
                class="button button--primary button--full button--lg push-top"
                @click="goToEmailRegistration"
              >
                メールアドレスを登録する
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '../../../../common/routings/utils';

export default {
  computed: {
    ...mapGetters(['cannotReceiveCurrentNotification', 'registrationPath'])
  },

  methods: {
    goToEmailRegistration() {
      utils.goToURL(this.registrationPath);
    }
  }
};
</script>
