<template>
  <transition name="modal">
    <div
      class="modal-mask modal-mask-scroll-sp modal-center"
      v-if="reachToLimitModalVisible"
    >
      <div class="modal-wrapper">
        <div class="saved-dialog">
          <button
            @click.prevent="hideReachToLimitModal"
            class="close-button-top"
          >
            <i class="material-icons">close</i>
          </button>
          <div>
            <div class="text-center saved-dialog-title">
              <p>保存条件が上限に達しています</p>
            </div>
            <div class="saved-dialog-body">
              <p>
                新しい条件を追加するには、保存中の条件を削除してください。（{{
                  savableCriteriaLimit
                }}件まで保存可能）
              </p>
              <button
                type="button"
                class="button button--primary button--full button--lg push-top"
                @click="goToSearchCriteria"
              >
                保存中の条件を削除
              </button>
            </div>
            <div class="text-center close-button-bottom">
              <a
                class="link-underline"
                href="#"
                @click.prevent="hideReachToLimitModal"
                >閉じる</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import utils from '../../../../common/routings/utils';

export default {
  computed: {
    ...mapGetters([
      'reachToLimitModalVisible',
      'searchCriteriaPath',
      'savableCriteriaLimit'
    ])
  },

  methods: {
    ...mapActions(['hideReachToLimitModal']),

    goToSearchCriteria() {
      utils.goToURL(this.searchCriteriaPath);
    }
  }
};
</script>
