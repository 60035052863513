<template>
  <div
    class="header-sp__search-trigger"
    role="toggle-next-fade"
    v-bind:class="{ active: search_app_active }"
  >
    <i
      v-if="showMode == 'icon'"
      class="glyphs glyphs-search"
      aria-hidden="true"
      @click.prevent="showSearchScreenWithScroll"
    ></i>
    <div v-else class="open header-sp__search-box">
      <i class="glyphs glyphs-search" aria-hidden="true"></i>
      <input
        type="search"
        class="js-whole-search-button current_keyword_placeholder"
        @click.prevent="showSearchScreenWithScroll"
        placeholder="病名、症状、体の部位名、お薬名から探す"
        :value="current_keyword"
        readonly
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['showMode'],
  computed: {
    ...mapGetters(['search_app_active', 'current_keyword'])
  },
  methods: {
    ...mapActions(['showSearchScreen']),

    showSearchScreenWithScroll() {
      // TODO: Because of the way the search popup is made, opening it after scrolling
      // to the middle of the page opens the popup scrolled to the bottom, with the input
      // invisible. As this is outside the scope of ASKD-3003, we fix the issue by scrolling
      // to the top. If the behavior of the search popup is changed in the future, please DELETE
      // this window.scrollTo.
      window.scrollTo(0, 0);
      this.showSearchScreen();
    }
  }
};
</script>
