<template>
  <div class="mobile-only">
    <trigger-button :show-mode="showMode" />
    <search-screen />
  </div>
</template>

<script>
import TriggerButton from './TriggerButton.vue';
import SearchScreen from './SearchScreen.vue';

export default {
  components: { TriggerButton, SearchScreen },
  props: ['showMode']
};
</script>
