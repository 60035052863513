<template>
  <div class="search-save-button__top" v-if="isCurrentCriteriaSavable">
    <button type="button" class="button" @click="showSaveCriteriaDialog">
      <i class="item--before glyphs glyphs-save" aria-hidden="true"></i>
      条件を保存
    </button>
    <p>保存条件の新着Q&amp;Aもメールでお知らせ</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { captureEvent } from '../../../../common/utils';

export default {
  computed: {
    ...mapGetters(['isCurrentCriteriaSavable'])
  },

  methods: {
    ...mapActions(['showDialog']),

    showSaveCriteriaDialog() {
      captureEvent({
        category: 'サイト内検索',
        action: 'CriteriaPartsClick',
        label: 'top-button'
      });
      this.showDialog();
    }
  }
};
</script>
