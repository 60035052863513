import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { createApp } from 'vue';
import SearchApp from '../search/components/sp/SearchApp';
import SearchBox from '../search/components/pc/SearchBox';
import store from '../search/store';
import { buildSearchStore } from '../search/store';
import * as types from '../search/store/mutation-types';
import SaveButtonTop from '../search/components/common/criteria/SaveButtonTop';
import SaveButtonBottom from '../search/components/common/criteria/SaveButtonBottom';
import SaveDialog from '../search/components/common/criteria/SaveDialog';
import EmailRegistrationModal from '../search/components/common/criteria/EmailRegistrationModal';
import ReachToLimitModal from '../search/components/common/criteria/ReachToLimitModal';

const searchApp = createApp(SearchApp).use(store);
const searchIcon = createApp(SearchApp, { showMode: 'icon' }).use(store);
const searchBox = createApp(SearchBox).use(store);

document.addEventListener('DOMContentLoaded', () => {
  const searchPropsEl = document.getElementById('search-props-data');
  const searchPropsData = searchPropsEl
    ? JSON.parse(searchPropsEl.dataset.searchProps)
    : {};
  store.dispatch('mount', searchPropsData);
  store.commit(types.INITIALIZED);

  const searchWordEl = document.getElementById('search-word');
  if (searchWordEl) {
    const searchWord = searchWordEl.dataset.searchWord;
    store.commit(types.SET_CURRENT_KEYWORD, searchWord);
    store.dispatch('addToHistories', searchWord);
  }

  {
    // id要素でmountすると複数に対応できないので基本classを使うべき
    document.querySelectorAll('.js-search-components').forEach(el => {
      // 複数の検索欄でstoreを共有しても正常に動かないので個別に作る
      const store = buildSearchStore();
      store.dispatch('mount', searchPropsData);
      store.commit(types.INITIALIZED);

      const classAndApps = {
        'js-search-app': SearchApp,
        'js-search-box': SearchBox
      };
      Object.keys(classAndApps).forEach(className => {
        const appEl = el.querySelector(`.${className}`);

        const props = appEl?.dataset?.props
          ? JSON.parse(appEl.dataset.props)
          : {};

        createApp(classAndApps[className], props)
          .use(store)
          .mount(el.querySelector(`.${className}`));
      });
    });
  }

  if (document.getElementById('search-app')) {
    searchApp.mount('#search-app');
  }

  if (document.getElementById('search-icon')) {
    searchIcon.mount('#search-icon');
  }

  if (document.getElementById('search-box')) {
    searchBox.mount('#search-box');
  }

  // #criteria-save-props 要素のあるQ&A検索ページでは、検索条件保存機能の各種UI要素をmountする
  const criteriaSavePropsEl = document.getElementById('criteria-save-props');
  if (criteriaSavePropsEl) {
    mountSaveCriteriaApp(JSON.parse(criteriaSavePropsEl.dataset.props));
  }
});

const mountSaveCriteriaApp = function(propsData) {
  $.scrollSpy('#scrollSpy', 'main');
  store.dispatch('initializeSaveCriteria', propsData);

  // 各UIがページの別の場所にバラバラに存在しながら連携するので、storeで状態を共有しつつ個別にmountする
  // また、SearchApp, SearchBoxとも保存済みの検索条件の一覧をstoreを通して共有している。
  const componentAndEl = [
    [SaveDialog, 'criteria-save-dialog'],
    [SaveButtonTop, 'criteria-save-button-top'],
    [SaveButtonBottom, 'criteria-save-button-bottom'],
    [EmailRegistrationModal, 'criteria-email-registration-modal'],
    [ReachToLimitModal, 'criteria-reach-to-limit-modal']
  ];
  componentAndEl.forEach(args => {
    const component = args[0];
    const el = args[1];
    if (document.getElementById(el)) {
      createApp(component)
        .use(store)
        .mount('#' + el);
    }
  });
};
