<template>
  <div
    class="search-save-button"
    id="scrollSpy"
    v-if="isCurrentCriteriaSavable"
  >
    <div class="mobile-only">
      <button
        type="button"
        class="button search-save-button__inner"
        @click="showSaveCriteriaDialog"
      >
        <i class="item--before glyphs glyphs-save" aria-hidden="true"></i
        >検索条件を保存する
      </button>
    </div>

    <div class="search-save-button-wrap pc-only">
      <div class="search-save-button-wrap__inner clearfix">
        <div class="search-save-button-wrap__button">
          <button
            type="button"
            class="button search-save-button__inner"
            @click="showDialog"
          >
            <i class="item--before glyphs glyphs-save" aria-hidden="true"></i
            >検索条件を保存
          </button>
        </div>
        <div class="search-save-button-wrap__text text--xs">
          保存条件の新着Q&amp;Aもメールでお知らせします
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { captureEvent } from '../../../../common/utils';

export default {
  computed: {
    ...mapGetters(['isCurrentCriteriaSavable'])
  },

  methods: {
    ...mapActions(['showDialog']),

    showSaveCriteriaDialog() {
      captureEvent({
        category: 'サイト内検索',
        action: 'CriteriaPartsClick',
        label: 'bottom-button'
      });
      this.showDialog();
    }
  }
};
</script>
